import { RootState } from '@/store';
import { getBaseStore } from '@/store/base';
import { merge } from 'lodash';
import { ActionContext } from 'vuex';

export interface SupportThreadState {
    supportThread: SupportThread | null;
    supportThreads: SupportThreads | null;
    supportThreadOptions: SupportThreads | null;
}

export const supportThread = merge(getBaseStore('supportThread'), {
    actions: {
        async createSupportThread({ commit, state }: ActionContext<SupportThreadState, RootState>) {
            commit('updateSupportThread', {
                title: '',
                attachments: [{}],
                severity: 10,
            } as SupportThread);

            return state.supportThread;
        },
    },
});
